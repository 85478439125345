.courses-case {
    color: var(--Neutral-White, #FFF);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 12px;
    background: var(--Gradient-Dark, linear-gradient(0deg, #2279F5 8.33%, #00D2FE 91.67%));
    padding: 11px 20px;
    border: none;
    box-shadow: 0px 2px 40px 0px rgba(34, 121, 245, 0.15);
    display: block;
    cursor: pointer;
    transition: all 0.3sease;
}

.courses-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

}





.courses-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 550px;
    box-shadow: 24px;
    padding: 24px;
    background-color: #fff;
    outline: none;
    /* height: 660px; */
    border-radius: 12px;
    background: var(--Gradient-Light, linear-gradient(0deg, #CEE1FD 8.33%, #CCF6FF 91.67%));
    box-shadow: 0px 2px 40px 0px rgba(34, 121, 245, 0.15);
}

