.worklist-container {
    height: 720px;
    border-radius: 12px 0px 0px 12px;
    background: linear-gradient(0deg, #CEE1FD 8.33%, #CCF6FF 91.67%);
    width: 100%;
    
}

.header-title {
    font-family: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 350px;
}

.search-worklist {
    display: flex;
    align-items: center;
    padding: 0px 12px;
    border-radius: 12px;
    background: var(--Neutral-White, #FFF);
    height: 40px;
   
}


.worklist-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;

}

.filterBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 77%;
    flex-wrap: nowrap; 
}

.date-worklist,
.specialization {
    padding: 0px 12px;
    border-radius: 12px;
    border: 1px solid var(--Primary-10, #CEE1FD);
    background: var(--Neutral-White, #FFF);
    height: 40px;
}

.date-worklist {
    width: 140px;
    
}

.specialization {
    width: 130px;
    display: block;
}
.specialization-desktop{
    width: 130px;
    display: none;
    padding: 0px 12px;
    border-radius: 12px;
    border: 1px solid var(--Primary-10, #CEE1FD);
    background: var(--Neutral-White, #FFF);
    height: 40px;
}
.search-bar {
    width: 250px;
    outline: none; 
    flex-grow: 1; 
}



.create-case:hover {
    transform: translateY(-2px);
    box-shadow: 0px 4px 20px 0px rgba(34, 121, 245, 0.25);
    background: linear-gradient(0deg, #1e6ad8 8.33%, #00bfe8 91.67%);
}

.create-upload {
    color: var(--Neutral-White, #FFF);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 12px;
    background: var(--Gradient-Dark, linear-gradient(0deg, #2279F5 8.33%, #00D2FE 91.67%));
    padding: 11px 20px;
    border: none;
    box-shadow: 0px 2px 40px 0px rgba(34, 121, 245, 0.15);
    display: block;
    cursor: pointer;
    transition: all 0.3s ease;

}

.create-upload:hover {
    transform: translateY(-2px);
    transform: scale(1.02);
    box-shadow: 0px 4px 20px 0px rgba(34, 121, 245, 0.25);
    background: linear-gradient(0deg, #1e6ad8 8.33%, #00bfe8 91.67%);
}
.create-case {
    color: var(--Neutral-White, #FFF);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 12px;
    background: var(--Gradient-Dark, linear-gradient(0deg, #2279F5 8.33%, #00D2FE 91.67%));
    padding: 11px 20px;
    border: none;
    box-shadow: 0px 2px 40px 0px rgba(34, 121, 245, 0.15);
    display: block;
    cursor: pointer;
    transition: all 0.3s ease;
}
.create-case-desktop {
    display: none;
    color: var(--Neutral-White, #FFF);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 12px;
    background: var(--Gradient-Dark, linear-gradient(0deg, #2279F5 8.33%, #00D2FE 91.67%));
    padding: 11px 20px;
    border: none;
    margin-left: 20px;
    box-shadow: 0px 2px 40px 0px rgba(34, 121, 245, 0.15);
    cursor: pointer;
}

.delete-btn {
    color: var(--Neutral-20, #C4CBD4);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    justify-content: space-evenly;
    width: 90px;
    display: flex;
    align-items: center;
    height: 35px;
    border-radius: 12px;
    border: 1px solid var(--Neutral-10, #E2E5E9);
    background: var(--Neutral-White, #FFF);
    box-shadow: 0px 2px 40px 0px rgba(34, 121, 245, 0.15);
}

.worklist-head {
    display: flex;
    flex-direction: row-reverse;
    padding: 0px 20px 0px 0px;
}

.text-div1,.text-div2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 210px;
    justify-content: space-around;
    margin-right: 5px;
}
.text-div2{
    margin-left: -10px;
}
.text-div2-desktop{
    display: none;
    flex-direction: row;
    align-items: center;
    width: 210px;
    justify-content: space-around;
    
}

.small-checkbox .MuiSvgIcon-root {
    font-size: 1rem;
    /* Adjust the size as needed */
}

.table-row {
    background: var(--Neutral-White, #FFF);
    cursor: pointer;
    transition: background-color 0.2s ease-in, transform 0.2s ease-out;
}

.table-row:hover {
    background-color: #f0f0f0;
    transform: scale(1.01);
}

.table-head {

    font-size: 12px !important;
    font-weight: 600 !important;
    padding: 0px 0px 5px 0px !important;
    border-bottom: none !important;
}

.details {
    color: var(--Neutral-50, #6C7C93);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.table-cell {
    padding: 0px !important;
    font-family: Roboto;
    font-size: 13px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    height: 90px;
    border-bottom: none !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

}
.table-cells {
    padding: 10px !important;
    font-family: Roboto;
    font-size: 13px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    height: 70px;
    border-bottom: none !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

}

.table-container {
    padding: 20px;
    height: 500px;
}



.verifiedIcon {
    width: 15px !important;
    height: 15px !important;
}

.flagIcon {
    width: 15px !important;
    height: 15px !important;
}

.addCommentIcon {
    color: rgba(250, 194, 10, 1);
    width: 15px !important;
    height: 15px !important;

}

.shareIcon {
    color: rgba(71, 210, 115, 1);
    width: 15px !important;
    height: 15px !important;
}

.removeRedEyeIcon {
    color: rgba(0, 210, 254, 1);
    width: 15px !important;
    height: 15px !important;
}

.editIcon {
    color: rgba(34, 121, 245, 1);
    width: 15px !important;
    height: 15px !important;
}

.deleteIcon {
    color: rgba(251, 59, 66, 1);
    width: 15px !important;
    height: 15px !important;
}


@media only screen and (max-width: 1635px) {

    .filterBox {
        width: 100%;

    }
}
@media only screen and (max-width:1565px){
    .create-case{
        font-size: 13px;
        padding: 10px;
        width: auto; 
        white-space: nowrap; 
    }
}

@media only screen and (max-width: 1540px) {
    .worklist-container {
        height: 724px;
    }

    .filterBox {
        width: 85%;

    }

    .header-title {

        width: 230px;
    }

    .date-worklist,
    .specialization,
    .search-bar {
        padding: 0px 7px;
        border-radius: 12px;
        border: 1px solid var(--Primary-10, #CEE1FD);
        background: var(--Neutral-White, #FFF);
        height: 40px;
    }

    .date-worklist {
        width: 120px;
        /* Adjusted width for better responsiveness */
    }

    .specialization {
        width: 120px;
    }

    .search-bar {
        width: 250px;
        /* Adjusted width for better responsiveness */
    }

    .text-div {
        width: 166px;
    }
}

@media only screen and (max-width: 1450px) {
    .header-title {

        width: 170px;
    }
}



@media only screen and (max-width: 1381px) {
    .create-case {
        display: none;
    }

    .create-case-desktop {
        display: block;
    }

    .header-title {
        font-family: Roboto;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        width: 150px;
    }
}
@media only screen and (max-width: 1150px) {
    .specialization {
        display: none;
    }
    .specialization-desktop{
        display:block;
        margin-left: 10px;
        
    }
}

@media only screen and (max-width: 1024px) {
    .text-div1,.text-div2{
        margin-left: 5px;
    } 
}

@media only screen and (max-width: 1000px) {
    .text-div2{
        display: none;
    }
    .text-div2-desktop{
        display:block;
       margin-right: -40px;
       margin-left: 10px;
    }
    .text-div1{
        margin-right: -50px;
    }
    .filterBox{
        margin-left: 80px;
    }
}