/* ShareLogin.css */


.login-container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
  }
  
  .login-card {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
  }
  
  .login-title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1.5rem;
    color: #333;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .form-group label {
    font-size: 14px;
    font-weight: 500;
    color: #555;
  }
  
  .password-input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    transition: border-color 0.2s;
  }
  
  .password-input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.1);
  }
  
  .login-button {
    background-color: #007bff;
    color: white;
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .login-button:hover {
    background-color: #0056b3;
  }
  
  .login-button:active {
    background-color: #004494;
  }
  
  .error-message {
    background-color: #fff5f5;
    border: 1px solid #feb2b2;
    color: #c53030;
    padding: 0.75rem;
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
  }
  
  /* Responsive adjustments */
  @media (max-width: 480px) {
    .login-card {
      margin: 1rem;
      padding: 1.5rem;
    }
  }

  /* ShareModal.css */
.share-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.share-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 520px;
  box-shadow: 24px;
  padding: 24px;
  outline: none;
  border-radius: 12px;
  background: var(--Gradient-Light, linear-gradient(0deg, #CEE1FD 8.33%, #CCF6FF 91.67%));
  box-shadow: 0px 2px 40px 0px rgba(34, 121, 245, 0.15);
  max-height: 600px;
  overflow: auto;
  
}

.close-button {
  position: absolute;
  right: 16px;
  top: 16px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  padding: 4px;
  line-height: 1;
}

.modal-title {
  text-align: center;
  margin: 0 0 24px;
  color: #333;
  font-size: 20px;
  font-weight: 600;
}

.visibility-container {
  background-color: white;
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: space-between
}

.visibility-header {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #666;
  font-size: 14px;
}

.info-icon {
  color: #999;
  cursor: help;
  font-size: 16px;
}

.visibility-options {
  display: flex;
  gap: 24px;
}

.visibility-option {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.visibility-option input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.visibility-radio {
  position: relative;
  height: 20px;
  width: 20px;
  border: 2px solid #ddd;
  border-radius: 50%;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.visibility-option input[type="radio"]:checked + .visibility-radio {
  border-color: #2279F5;
}

.visibility-option input[type="radio"]:checked + .visibility-radio:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #2279F5;
}

.visibility-text {
  font-size: 14px;
  color: #333;
}

/* Hover states */
.visibility-option:hover .visibility-radio {
  border-color: #2279F5;
}
.section-label {
  display: block;
  margin-bottom: 12px;
  color: #666;
  font-size: 14px;
}

.radio-group {
  display: flex;
  gap: 24px;
}

.radio-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.radio-label input[type="radio"] {
  margin-right: 8px;
  cursor: pointer;
}

.radio-text {
  color: #333;
  font-size: 14px;
}

.share-options {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 12px;
  margin-bottom: 24px;
}

.share-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 8px;
  border: none;
  background: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.share-option:hover {
  background-color: #f5f5f5;
}

.share-option.selected {
  background-color: #e3f2fd;
  color: #1976d2;
}

.option-icon {
  font-size: 24px;
  margin-bottom: 4px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fb-icon {
  background: #1877f2;
  color: white;
  border-radius: 50%;
  font-size: 20px;
  font-weight: bold;
}

.linkedin-icon {
  background: #0077b5;
  color: white;
  border-radius: 4px;
  font-size: 16px;
  padding: 2px 4px;
}

.option-label {
  font-size: 12px;
  text-align: center;
  color: #666;
}

.input-group {
  margin-bottom: 16px;
}

.input-group label {
  display: block;
  margin-bottom: 8px;
  color: #666;
  font-size: 14px;
}



.input-field:focus {
  outline: none;
  border-color: #1976d2;
}

.share-link-container {
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
}

.option-icon {
  font-size: 24px;
  margin-bottom: 4px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
}

/* Individual icon background colors */
.share-option[data-option="link"] .option-icon {
  background-color: #FFD700; /* Yellow for link */
}

.share-option[data-option="qrcode"] .option-icon {
  background-color: #225ca6; /* Green for QR code */
}

.share-option[data-option="email"] .option-icon {
  background-color: #4CAF50; /* Green for email */
}

.fb-icon {
  background-color: #1877f2 !important; /* Facebook blue */
  border-radius: 50%;
  font-size: 20px;
  font-weight: bold;
}

.share-option[data-option="twitter"] .option-icon {
  background-color: #000000; /* Black for Twitter/X */
}

.linkedin-icon {
  background-color: #0077b5 !important; /* LinkedIn blue */
  border-radius: 50%;
  font-size: 16px;
}

.share-option[data-option="whatsapp"] .option-icon {
  background-color: #25D366; /* WhatsApp green */
}

/* Rest of the CSS remains the same */
.share-link-input {
  flex: 1;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
  background-color: #f5f5f5;
}
.pass-{
  display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

}
.input-fieldDate {
  width: 227px;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
  transition: border-color 0.2s ease;
}
.copy-button {
  padding: 8px 16px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;
}

.copy-button:hover {
  background-color: #e0e0e0;
}

.modal-actions {
  display: flex;
  gap: 12px;
  margin-top: 24px;
}


.tool-button-share {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 20px;
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  border: 1px solid transparent;
  color:#f5f5f5;
}

.tool-button-share:hover {
  background-color: #1c3c69;
}

.tool-button-share.selected {
  background-color: #007bff;
  color: white;
}

.tool-button-share.selected svg {
  fill: rgb(255, 255, 255);
}

.cancel-button,
.share-button {
  flex: 1;
  padding: 12px;
  border-radius: 8px;
  border: none;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  font-weight: 500;
}

.cancel-button {
  background-color: #f5f5f5;
  color: #666;
}

.share-button {
  background: var(--Gradient-Dark, linear-gradient(0deg, #2279F5 8.33%, #00D2FE 91.67%));  color: white;
}

.cancel-button:hover {
  background-color: #e0e0e0;
}

.share-button:hover {
  background-color: #1565c0;
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .share-options {
    grid-template-columns: repeat(3, 1fr);
  }
  
  .share-modal {
    width: 95%;
    padding: 20px;
  }
  
  .modal-title {
    font-size: 18px;
    margin-bottom: 20px;
  }
}